import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { graphql } from "gatsby"
import dayjs from "dayjs"
import Gallery from "react-photo-gallery"

import "../styles/home.scss"
import SEO from '../components/seo'
import DynamicColor from '../components/dynamic-color'
import { getBusinessTypes } from '../utils/utils'
import Shortcut from "../components/shortcut"
import Layout from "../components/layout"
import useResponsive from '../utils/useResponsive'
import SocialLogo from "../components/social-logo"
import Services from "../components/services"
import { FaAngleDoubleDown } from "react-icons/fa";

const currentDay = dayjs().day()
const DailySchedule = (props) => {
  return (
    <div key={props.day} className={`flex flex-row mb-3 mx-auto ${currentDay === props.weekDay ? "font-bold" : ""}`}>
      <div className="w-24">
        {props.day}
      </div>
      <div className="flex">
        {props.dailySchedule.length !== 0 && props.dailySchedule.map((schedule, idx) => (
          <div key={idx}>{schedule}&nbsp;&nbsp;&nbsp;</div>
        ))}
        {props.dailySchedule.length === 0 && (
          <div>Fermé</div>
        )}
      </div>
    </div>
  )
}

export default function Home({ data }) {
  const schedules = data.restaurant.settings.schedule
  const photos = data.restaurant.websiteSettings?.restoImages.map(img => {
    return {
      src: img.path,
      width: 1,
      height: 1,
    }
  })

  return (
    <Layout data={data}>
      <SEO />
      <DynamicColor data={data} />
      <div className="home-page">
        <header>
          <div className="background" style={{ backgroundImage: `url(${data.restaurant.websiteSettings?.restoImages[0].path}` }}>
            {/* <div className="background" style={{ backgroundImage: `url(${data.restaurant.websiteSettings?.restoImages[0].path}` }}> */}
            <div className="absolute inset-0 bg-gradient-to-t from-black opacity-80 top-1/4"></div>
            <div className="flex flex-col items-start absolute bottom-16 left-4 lg:bottom-20 lg:left-20">
              <h1 className="text-4xl lg:text-6xl">
                {data.restaurant.informations.name}
              </h1>
              <div className="business-type">
                {getBusinessTypes(data.restaurant.informations.businessType)}
              </div>
              <div>
                {data.restaurant.informations.address}
              </div>
              <div>
                {data.restaurant.informations.postalCode} {data.restaurant.informations.city}
              </div>
              <div className="mt-3 lg:mt-7">
                <SocialLogo data={data} color="bright" />
              </div>
            </div>
            <div className="absolute bottom-2 lg:bottom-10 right-1/2 left-1/2">
              <FaAngleDoubleDown color="white" size="2rem"/>
            </div>
            <div className="hidden lg:flex lg:flex-col lg:items-start lg:absolute  lg:bottom-32 lg:right-20">
              <Shortcut data={data} />
            </div>
          </div>
        </header>

        <div className="information py-6 bg-gray-50">
          <h2 className="deco text-black text-3xl text-center py-3">Qui sommes nous ?</h2>
          <div className="max-w-4xl m-auto py-3 px-2" dangerouslySetInnerHTML={{ __html: data.restaurant.websiteSettings?.introMessage }} />
        </div>



        <Gallery
          photos={photos}
          targetRowHeight={useResponsive().isMobile ? 200 : 500}
        />

        <div className="flex flex-col lg:flex-row lg:items-start items-center justify-evenly pt-10 pb-20 bg-gray-50">
          <div>
            <h2 className="deco text-black text-3xl py-10 text-center">Nos services</h2>
            <div className="">
              <Services data={data} />
            </div>
          </div>
          <div>
            <h2 className="deco text-black text-3xl text-center py-10 mt-10 lg:mt-0">Horaire d'ouverture</h2>
            <div className="flex flex-col">
              <DailySchedule day="Lundi" weekDay={1} dailySchedule={schedules[0].dailySchedule} />
              <DailySchedule day="Mardi" weekDay={2} dailySchedule={schedules[1].dailySchedule} />
              <DailySchedule day="Mercredi" weekDay={3} dailySchedule={schedules[2].dailySchedule} />
              <DailySchedule day="Jeudi" weekDay={4} dailySchedule={schedules[3].dailySchedule} />
              <DailySchedule day="Vendredi" weekDay={5} dailySchedule={schedules[4].dailySchedule} />
              <DailySchedule day="Samedi" weekDay={6} dailySchedule={schedules[5].dailySchedule} />
              <DailySchedule day="Dimanche" weekDay={0} dailySchedule={schedules[6].dailySchedule} />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-96">
        <iframe title="google map" className="w-full h-96" frameBorder="0" src={`https://www.google.com/maps/embed/v1/place?q=place_id:${data.restaurant.websiteSettings.googlePlaceId}&key=${process.env.GATSBY_GOOGLE_EMBED_API_KEY}`} allowFullScreen></iframe>
      </div>
    </Layout >
  )
}

export const query = graphql`
  query PageQuery ($restoId: String!) {
    restaurant(id: {eq: $restoId}) {
      urlName
      informations {
        businessType
        serviceType
        address
        postalCode
        city
        name
        phone
        restoId
      }
      settings {
        color
        takeaway
        delivery
        schedule {
          dailySchedule
        }
      }
      websiteSettings {
        reservation
        googlePlaceId
        googleMapsUrl
        deliverooUrl
        uberEatsUrl
        logo {
          path
        }
        restoImages {
          path
        }
        linkFacebook
        linkInstagram
        linkYelp
        linkTwitter
        linkFoursquare
        linkTripadvisor
        introMessage
        popupMessage
        color
        colorSecond
        colorBackground
      }
    }
  }
`